import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NativeMarket } from '@capacitor-community/native-market';
import { App } from '@capacitor/app';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { AlertController, IonNav, IonRouterOutlet, Platform } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { compare } from 'compare-versions';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth/auth.service';
import { Events } from './services/events/events.service';
import { LanguageService } from './services/language/language.service';
import { NotificationService } from './services/notification/notification.service';
import { ParamService } from './services/param/param.service';
import { ThemeEnum, ThemeService } from './services/theme/theme.service';
import { Preferences } from '@capacitor/preferences';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [IonNav, IonRouterOutlet],
})
export class AppComponent implements OnInit, OnDestroy {
  public env = environment.env;

  constructor(
    private translate: TranslateService,
    private platform: Platform,
    private themeService: ThemeService,
    private languageService: LanguageService,
    private notificationService: NotificationService,
    private events: Events,
    private paramService: ParamService,
    private alertCtrl: AlertController,
    private authService: AuthService,
  ) {}

  async ngOnInit() {
    this.events.subscribe('howManyNotifications', async () => {
      const result = await firstValueFrom(this.notificationService.howManyNotificationNotRead());
      this.events.publish('notifications:length', result);
    });

    try {
      const source = await this.platform.ready();
      if (source !== 'dom') {
        // actions to do on mobile
        await this.themeService.setupTheme();
        await this.authService.loadUser();
        await this.languageService.setupLanguage();
        try {
          await StatusBar.setStyle({ style: ThemeService.currentTheme === ThemeEnum.DARK ? Style.Dark : Style.Light });
          await StatusBar.show();
        } catch (err) {
          console.error('Error hiding status bar => ', err);
        }
        if (!this.platform.is('ipad') && !this.platform.is('tablet')) {
          try {
            await ScreenOrientation.lock({ orientation: 'portrait' });
          } catch (err) {
            console.error('Error locking orientation in portrait mode => ', err);
          }
        }

        try {
          await SplashScreen.hide();
        } catch (err) {
          console.error('Error hiding splash: ', err);
        }
        await this.versionVerification();

        // TODO: patch pour la version 1.11.1 à enlever pour la prochaine version
        await this.checkNewVersionLogin();
      } else {
        // actions to do on web
        await this.themeService.setupTheme();
        await this.authService.loadUser();
        await this.languageService.setupLanguage();
      }
    } catch (err) {
      console.error('Error platform ready => ', err);
    }
  }

  ngOnDestroy() {
    this.events.unsubscribe('howManyNotifications');
  }

  async checkNewVersionLogin() {
    // security.authentication.jwt.token-validity-in-seconds
    // security.authentication.jwt.token-validity-in-seconds-for-remember-me

    const appInfo = await App.getInfo();
    const currentVersion = appInfo.version;
    const { value } = await Preferences.get({
      key: 'newVersionFirstInstall',
    });
    if (currentVersion == '1.11.1' && value !== 'no') {
      await Preferences.set({
        key: 'newVersionFirstInstall',
        value: 'no',
      });
      await this.authService.logout(false);
    }
  }

  async versionVerification() {
    const targetVersion = await firstValueFrom(
      this.paramService.getParamValue('APP_VERSION', 'MINIMUM_VERSION_REQUIRED'),
    );
    const appInfo = await App.getInfo();
    const currentVersion = appInfo.version;
    // si la version de la base est supérieure à la version courante
    if (compare(targetVersion, currentVersion, '>')) {
      const displayAlert = (appId: string) => {
        this.translate.get(['OK', 'UPDATE_MOBILE_APPLICATION']).subscribe(async (value) => {
          const alert = await this.alertCtrl.create({
            message: value.UPDATE_MOBILE_APPLICATION,
            backdropDismiss: false,
            buttons: [
              {
                text: value.OK,
                handler: () => {
                  NativeMarket.openStoreListing({ appId });
                  return false;
                },
              },
            ],
          });
          await alert.present();
        });
      };
      if (this.platform.is('android')) {
        displayAlert('com.amer.s2atesting');
      } else if (this.platform.is('ios')) {
        displayAlert('1459881515');
      }
    }
  }
}
